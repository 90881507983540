import React, { useEffect, useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import SblcConfirm from "../../common/components/sblcComponent/SblcConfirm";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useGlobalState } from "../../common/GlobalProvider/GlobalStateProvider";
import JSZip from "jszip";
import * as FileSaver from "file-saver";

const Sblc = ({ islabel, setIsLabel }) => {
  const [isCount, setIsCount] = useState(2);
  const { setPageContent } = useGlobalState();
  const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  console.log("retrievedData", retrievedData?.sblcDocument);
  // useEffect(() => {
  //   setIsLabel(document.getElementById("sblc_Screen"));
  // },[islabel&&islabel]);

  // const handleDownload = () => {
  //   // Create and download PDF for Page 1
  //   // if (page1Canvas) {
  //   //   const doc1 = new jsPDF();
  //   //   const imgData1 = page1Canvas.toDataURL("image/png");
  //   //   doc1.addImage(imgData1, "PNG", 0, 0);
  //   //   doc1.save("page1.pdf");
  //   // }

  //   // Create and download PDF for Page 2
  //   const page2Element = document.getElementById("sblc_Screen")
  //   html2canvas(page2Element, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = pdfWidth;

  //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, pdfHeight);
  //     pdf.save("sblc-confirm.pdf");
  //   });

  // };
  // useEffect(() => {
  //   const capturePage1 = async () => {
  //     const page1Element = document.getElementById('sblc_Screen');
  //     const canvas = await html2canvas(page1Element);
  //     setPage1Content(canvas.toDataURL('image/png'));
  //   };

  //   capturePage1();
  // }, [setPage1Content]);
  useEffect(() => {
    const capturePage2 = async () => {
      const page1Element = document.getElementById("sblc_Screen");
      const canvas = await html2canvas(page1Element);
      setPageContent("page1Content", canvas.toDataURL("image/png"));
    };

    capturePage2();
  }, [setPageContent]);

  const handleDownload = async () => {
    const page1Element = document.getElementById("sblc_Screen");
    const canvas = await html2canvas(page1Element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("page1.pdf");
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDownloadAll = async () => {
    setIsProcessing(true);
    const zip = new JSZip();

    try {
      // Convert the content of the page to PDF
      const pageContent = document.getElementById("sblc_Screen");
      if (pageContent) {
        const canvas = await html2canvas(pageContent);
        const imgData = canvas.toDataURL("image/png");

        // Create PDF with proper dimensions
        const pdf = new jsPDF("p", "pt", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

        // Save the PDF directly and convert to Base64
        const pdfBase64 = pdf.output("datauristring").split(",")[1];
        zip.file("Slbc.pdf", pdfBase64, { base64: true });
      }

      // Add Excel file to the ZIP file
      if (retrievedData?.sblcDocument) {
        zip.file("File.xlsx", retrievedData?.sblcDocument, { base64: true });
      }

      // Generate the ZIP file and trigger download
      const content = await zip.generateAsync({ type: "blob" });
      FileSaver.saveAs(content, "sblc.zip");
    } catch (error) {
      console.error("Error during download:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: "3rem" }}>
              <div className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="statusWaiverMainContainer">
                  <div id="sblc_Screen">
                    <SblcConfirm />
                  </div>
                  <div className="d-flex justify-content-center py-5">
                    <button
                      className="summary-download-btn"
                      onClick={() => handleDownloadAll()}
                    >
                      {isProcessing ? "Loading...." : "Download"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel={"SBLC"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sblc;
