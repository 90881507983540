import React, { useEffect, useState } from "react";
import Images from "../../assets";
import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default function ForwardCoverTable() {
  const fetchUserDetails = async () => {
    return await axios.get(`${process.env.REACT_APP_HOST}/invoices`);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { data, isLoading, isError } = useQuery(
    "forwardcovertable",
    fetchUserDetails
  );
  let details = data && data?.data;

  useEffect(() => {
    // Filtering and searching logic
    const applyFilterAndSearch = () => {
      const result =
        details &&
        details?.filter((item) => {
          // Determine status based on exchangeRate
          const derivedStatus = item.exchangeRate ? "INACTIVE" : "ACTIVE";

          // Check if the item matches the filter criteria
          const matchesFilter = filter ? derivedStatus === filter : true;

          // Check if the item matches the search term
          const matchesSearch = searchTerm
            ? (item.bgNumber ?? "")
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase())
            : true;

          // Return true only if both criteria are matched
          return matchesFilter && matchesSearch;
        });

      setFilteredData(result);
    };

    applyFilterAndSearch();
  }, [details, filter, searchTerm]);

  // console.log("filterData",filteredData)

  // DOWNLOAD EXCEL FORMAT FORWARDCOVERTABLE
  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData = filteredData || details;

    const tableData =
      exportData &&
      exportData?.map((data, index) => ({
        "S.no": index + 1,
        "FILE NAME": data?.fileName || "NULL",
        "BG NUMBER": data?.bgNumber || "NULL",
        USD: data?.bgAmtAndInterestAmt || "NULL",
        DATE: data?.bankDeductionDate || "NULL",
        RATE: data?.exchangeRate || "NULL",
        STATUS: data?.exchangeRate ? "INACTIVE" : "ACTIVE",
      }));

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(tableData, {
      header: [
        "S.no",
        "FILE NAME",
        "BG NUMBER",
        "USD",
        "DATE",
        "RATE",
        "STATUS",
      ],
      skipHeader: false, // Ensure the header is included
    });

    // Create summary data
    const summaryData =
      exportData && exportData?.length > 0
        ? [
            {
              Label: "FILE NAME",
              Value:
                exportData?.map((data) => data.fileName).join(", ") || "NULL",
            },
            {
              Label: "BG NUMBER",
              Value:
                exportData?.map((data) => data.bgNumber).join(", ") || "NULL",
            },
            {
              Label: "USD",
              Value:
                exportData?.reduce(
                  (sum, data) => sum + (data.bgAmtAndInterestAmt || 0),
                  0
                ) || "NULL",
            },
            {
              Label: "DATE",
              Value:
                exportData
                  ?.map((data) =>
                    moment(data.bankDeductionDate).format("DD/MM/YYYY")
                  )
                  .join(", ") || "NULL",
            },
            {
              Label: "RATE",
              Value:
                exportData?.map((data) => data.exchangeRate).join(", ") ||
                "NULL",
            },
            {
              Label: "STATUS",
              Value: exportData?.some((data) => data.exchangeRate)
                ? "INACTIVE"
                : "ACTIVE",
            },
          ]
        : [];

    // Create summary worksheet with headers
    const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData, {
      header: ["Label", "Value"],
      skipHeader: false,
    });

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");
    XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Summary Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Forward_Cover.xlsx");
  };

  let totalamt = 0;
  return (
    <>
      <div className="headerTimeLine px-3 py-3">
        <div className="px-5 d-flex justify-content-between">
          <div className="d-flex search-block">
            <input
              type="text"
              className="table-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="image_icon">
              <img
                src={Images.searchIcons}
                style={{ width: "100%", height: "100%" }}
                alt="Search"
              />
            </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "25%" }}
          >
            <div className="add-user-btn-block">
              <select
                className="new-user-btn text-center"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
              >
                <option value="">All</option>
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </select>
            </div>
            <div className="add-user-btn-block" onClick={() => downloadExcel()}>
              <button className="new-user-btn">DOWNLOAD</button>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 mt-3">
        <div className="view-table-block-forward  px-1">
          <table className="view-user-table">
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr>
                <th className="view-user-table-head --first-head">S.No</th>
                <th className="view-user-table-head">FILE Name</th>
                <th className="view-user-table-head">BG Number</th>
                <th className="view-user-table-head">USD</th>
                <th className="view-user-table-head">Date</th>
                <th className="view-user-table-head">Rate</th>
                <th className="view-user-table-head --last-head">Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredData && filteredData.length > 0 ? (
                filteredData?.map((item, i) => {
                  totalamt = totalamt + item?.contractAmount;
                  return (
                    <tr key={i} className="view-user-table-row">
                      <td className="view-user-table-data">{i + 1}</td>
                      <td className="view-user-table-data">{item?.fileName}</td>
                      <td className="view-user-table-data">{item?.bgNumber}</td>
                      <td className="view-user-table-data">
                        {item?.contractAmount || null}
                      </td>
                      <td className="view-user-table-data">
                        {moment(item?.bankDeductionDate).format("DD/MM/YYYY")}
                      </td>
                      <td className="view-user-table-data">
                        {item?.exchangeRate}
                      </td>
                      <td className="view-user-table-data">
                        {item?.exchangeRate ? "INACTIVE" : "ACTIVE"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <p className="pt-2 px-1">No Results Found</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="summary-head px-5 py-3">
        <label className="summary-label">TOTAL AMOUNT</label>
        <div style={{ width: "35%" }}>
          <input
            type="text"
            name="totalAmount"
            className="billNoSearchField --hiddens"
            placeholder="3421631"
            value={totalamt}
            disabled
          />
        </div>
      </div>
    </>
  );
}
